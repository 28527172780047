import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image'

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout';
import PartnerCard from '../components/PartnerCard';

import './mystyles.scss';

const PartnersPage = ({ data, pageContext }) => {
  const partners = data.allMdx.nodes;

  return (
    <Layout title="Partner" heroTitle="An Ihrer Seite" heroSubtitle="Für Sie im Einsatz" imageData={data.heroImage.childImageSharp.gatsbyImageData} pageContext={pageContext}>
      
      <section className="section">
      
        <div className="container">
          <h1 id="partner" className="title">Die Partner</h1>
          
          <div className="tile is-ancestor">
          
            {
              partners.map((partner) => (
                <div className="tile is-parent"><div className="tile is-child is-flex">
                    <PartnerCard className="equal-height" partner={partner} style={{width: "100%"}}/> </div>
                </div>
              ))
            }
            
          </div>
          
          <h1 id="media" className="title">In den Medien</h1>
         
          {
            data.allMdxMedia.nodes.map((node) => (
              <div className="columns">
                <div className="column is-one-third is-hidden-mobile">
                  <a href={`${node.frontmatter.url}`} target="_blank" rel="noreferrer">
                    <BackgroundImage
                      Tag="div"
                      style={{height: "100%", filter: "saturate(0.5)"}}
                      {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                    >
                    </BackgroundImage>
                  </a>
                </div>
                <div className="column">
                  <a className="subtitle has-text-primary is-size-5" href={`${node.frontmatter.url}`} target="_blank" rel="noreferrer">
                    <h2 className="mb-1">
                      {node.frontmatter.title}
                    </h2>
                  </a>
                  <p className="has-text-grey">{node.frontmatter.date}</p>
                  <p className="is-hidden-tablet">
                    <a href={`${node.frontmatter.url}`} target="_blank" rel="noreferrer">
                      <BackgroundImage
                        Tag="div"
                        className="is-flex is-flex-direction-column is-justify-content-space-between"
                        style={{padding: "1.5rem 1.5rem 1.5rem 1.5rem", height: "8rem", filter: "saturate(0.5)"}}
                        {...convertToBgImage(getImage(node.frontmatter.hero_image))}
                      >
                      </BackgroundImage>
                    </a>
                  </p>
                  <p className="mb-0">{node.excerpt}</p>
                  {/*<Link className="button" to={`/${node.slug}`}>Weiterlesen <span className="triangle-right"></span></Link>*/}
                </div>
              </div>
            ))
          }
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`query PartnersQuery {
  heroImage: file(relativePath: {eq: "partner.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMdx(filter: {frontmatter: {templateKey: {eq: "partner-page"}}}) {
    nodes {
      frontmatter {
        partnerName
        description
        twitter
        linkedin
        email
        phone
        pgp_keyfile {
          publicURL
          name
          extension
        }
        pgp_fingerprint
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
      slug
    }
  }
  allMdxMedia: allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {templateKey: {eq: "media-snippet"}}}
  ) {
    nodes {
      frontmatter {
        title
        date(formatString: "DD. MMMM YYYY", locale: "de-DE")
        tags
        authors {
          frontmatter {
            partnerName
          }
        }
        url
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 3,
              width: 600,
              transformOptions: {
                cropFocus: CENTER
              }
            )
          }
        }
      }
      id
      slug
      excerpt(pruneLength: 200)
    }
  }
}
`

export default PartnersPage;
